<template>
  <li @click="restoreOrder(order)">
    <!--    <h5>{{ order.data.items[0].fields.order }}</h5>-->
    <h5>{{ order.data.name }}</h5>
    <p><span v-if="order.data.delivery_option" class="badge badge-info text-capitalize">{{ order.data.delivery_option }}</span></p>
    <div v-bind:key="order.key + '-item-' + idx " v-for="(item, idx) in order.data.items">
      <h5><span v-if="item.quantity > 1">{{ item.quantity }} x </span>{{ item.item }}</h5>
    </div>
  </li>
</template>

<script>

export default {
  name: 'OldOrder',
  props: ['order', 'restoreOrder'],
  data: function () {
    return {}
  },
  methods: {
    extra(data) {
      return JSON.parse(data)
    }
  },
  calculated: {}
}
</script>

<style scoped lang="scss">
li {
  font-size: 0.8em;
  text-align: center;
  position: relative;
  opacity: 0.8;
  margin: 20px;
  padding: 10px;
  display: block;
  border-radius: 5px;
  background-color: #cccccc;
  white-space: normal;
  // vertical-align: top;
}
.badge { background-color: #687a88 !important;}

</style>
