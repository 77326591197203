<template>
  <div>
    <ul id="old" style="display: block">
      <p id="currentTime">{{ now }}</p>
      <span id="currentOrdersCount">#{{ filteredOrders.length }}</span>
      <div class="clearfix" style="clear: both"></div>
      <h4>{{ station }}</h4>
      <div class="clearfix" style="clear: both"></div>
      <font-awesome-icon
        @click.stop.prevent="addPrinter = true"
        icon="wrench"
        style="font-size: 3.5em; padding: 10px"
      />
      <font-awesome-icon
        @click="logout"
        icon="sign-out-alt"
        style="font-size: 3.5em; padding: 10px"
      />
      <canvas
        id="print-item"
        style="display: none; height: 220px; width: 680px"
      ></canvas>
      <form @submit.prevent="sendMessage">
        <OldOrder
          :id="order.id"
          :restoreOrder="reviewOldOrder"
          :order="order"
          :key="'old-' + order.id"
          v-for="order in oldOrders"
        />
      </form>
    </ul>
    <ul
      id="active"
      style="display: grid; margin-top: 20px; margin-bottom: 20px"
    >
      <Order
        :categories="siteCategories"
        :channel="channel"
        :pos="pos"
        @onComplete="setComplete"
        :id="order.id"
        :order="order"
        :dbkey="order.dbkey"
        :key="order.id"
        v-for="(order, pos) in filteredOrders"
      />
      <font-awesome-icon
        @click="messages"
        id="msgLink"
        icon="comment"
        v-bind:style="msgStyle"
        flip="horizontal"
      />
    </ul>

    <audio id="newOrderChirp" autoplay>
      <source src="/chirp.mp3" type="audio/mp3" />
    </audio>

    <audio id="newOrderBell" autoplay>
      <source src="/bell.mp3" type="audio/mp3" />
    </audio>
    

    <div
      v-if="!token"
      class="modal"
      tabindex="-1"
      role="dialog"
      style="display: block"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content container">
          <div class="modal-header header">
            <img src="../assets/beamylogo_white.svg" alt="beamy logo" />
          </div>
          <h2 class="action-head">Kitchen display</h2>
          <form v-if="!code" @submit="login" class="form-container">
            <div class="modal-body form-group form">
              <p class="input-group-lg">
                <input
                  class="form-control radius"
                  type="text"
                  v-model="username"
                  placeholder="E-mail / Username"
                />
              </p>
              <p class="input-group-lg">
                <input
                  class="form-control radius"
                  type="password"
                  v-model="password"
                  placeholder="Password"
                />
              </p>
              <p class="error">{{ loginError }}</p>
            </div>
            <div class="modal-footer footer">
              <button type="submit" class="btn btn-primary btn-lg radius">
                Log In
              </button>
              <button
                v-if="code"
                @click.stop.prevent="code = !code"
                class="btn btn-primary btn-lg radius btn-2"
              >
                Log in using credentials
              </button>
              <button
                v-else
                @click.stop.prevent="code = !code"
                class="btn btn-primary btn-md radius btn-2"
              >
                Log in using a code
              </button>
            </div>
          </form>
          <form
            v-if="code"
            v-on:submit.prevent="codeLogin"
            class="form-container"
          >
            <div class="digits form">
              <input
                v-for="(digit, idx) in digits"
                :key="idx"
                class="digit-input"
                type="text"
                inputmode="numeric"
                name="code"
                maxlength="1"
                v-model="digits[idx]"
                @input="handleInput(idx, $event)"
                @keydown="handleKeydown(idx, $event)"
                @paste="onPaste(event)"
                :ref="`digitInput${idx}`"
                :autofocus="idx === 0"
              />
            </div>
            <div class="footer">
              <button type="submit" class="btn btn-primary btn-lg radius">
                Login
              </button>
              <button
                @click.stop.prevent="code = !code"
                class="btn btn-primary btn-md radius btn-2"
              >
                Log in using credentials
              </button>
            </div>
          </form>
        </div>
      </div>
      <LoadingSpinner v-if="loading" :spinnerMessage="'Retreiving data...'" class="login-spinner" />
    </div>

    <div
      id="siteSelector"
      v-if="token && stationsAvailable && !site"
      class="modal"
      tabindex="-1"
      role="dialog"
      style="display: block"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Choose Site</h5>
          </div>
          <div class="modal-body form-group">
            <ul>
              <li
                @click="selectSite(site)"
                :key="'company-' + company + '-site-' + idx"
                v-for="(site, idx) in sites"
              >
                {{ site }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div
      id="stationSelector"
      v-if="token && site && !station"
      class="modal"
      tabindex="-1"
      role="dialog"
      style="display: block"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Choose Station</h5>
          </div>
          <div class="modal-body form-group">
            <ul>
              <li
                @click="selectStation(station.name)"
                :key="
                  'company-' + company + '-site-' + site + '-station-' + idx
                "
                v-for="(station, idx) in filteredStations"
              >
                {{ station.name }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <MessageModal
      :open="messageModal"
      :sites="sites"
      :stations="filteredStations"
      :channel="channel"
      :company="company"
      :site="site"
      :messageRecieved="msg"
    />

    <div
      @click="closePrinterModal"
      v-if="addPrinter"
      class="modal light-bg"
      tabindex="-1"
      role="dialog"
      style="display: block"
    >
      <div class="modal-dialog scroll" role="document">
        <div class="modal-content modal-dialog-scrollable" @click.stop.prevent>
          <h2>Settings</h2>
          <section class="setting">
            <h3>Download Android App</h3>
            <a
              href="https://kds.beamy.nz/android/beamy_kds.apk"
              download
              target="_blank"
              ><button>Download</button></a
            >
          </section>
          <section class="setting">
            <h3>Set up printer</h3>
            <button @click.stop.prevent="searchForPrinter">
              Search for printers...
            </button>
            <div v-if="printerFound" class="setting-form">
              <div
                @click.stop.prevent="newPrinter(p)"
                v-for="p in printers"
                :key="p"
                class="button"
              >
                <p>Model: {{ p[1] }}</p>
                <p>IP address: {{ p[0] }}</p>
              </div>
            </div>
            <form v-if="printerSelected" class="setting-form">
              <h4>Selected Printer</h4>
              <div class="button">
                <p>Model: {{ printer.model }}</p>
                <p>IP: {{ printer.ip }}</p>
                <p>Label: {{ printer.label }}</p>
              </div>
              <h4>Change Label Size</h4>
              <select id="labels">
                <option disabled selected>Select...</option>
                <option v-for="label in getLabels" :value="label" :key="label">
                  {{ label }}
                </option>
              </select>
              <input
                type="submit"
                value="Save Printer"
                @click.stop.prevent="addLabelSize()"
                class="btn btn-primary btn-lg"
              />
            </form>
          </section>
          <section class="setting">
            <h3>Set wait times</h3>
            <button @click.stop.prevent="adjustWaitTime = !adjustWaitTime">
              Adjust wait times
            </button>
            <form v-if="adjustWaitTime" class="setting-form">
              <i>Adjust the color based on the order's wait time.</i>
              <small>Time in minutes</small>
              <div class="time-input">
                <label for="normal">On time:</label>
                <input
                  required
                  type="number"
                  id="waitTime"
                  name="normal"
                  v-model="normal"
                  class="waitTime"
                />
              </div>
              <div class="time-input">
                <label for="later">Late:</label>
                <input
                  required
                  type="number"
                  id="waitTime"
                  name="later"
                  v-model="later"
                  class="waitTime"
                />
              </div>
              <div class="time-input">
                <label for="late">Overdue:</label>
                <input
                  required
                  type="number"
                  id="waitTime"
                  name="late"
                  v-model="late"
                  class="waitTime"
                />
              </div>
              <input
                type="submit"
                @click.stop.prevent="saveNewWaitTimes"
                class="btn btn-primary btn-lg"
              />
              <span class="err-msg">Ensure times have been added for all</span>
            </form>
          </section>
          <section class="setting">
            <h3>Training mode</h3>
            <button @click="trainingToggle">Toggle traning mode</button>
            <i v-if="training">On</i>
          </section>
        </div>
      </div>
      <LoadingSpinner
        v-if="loadingPrinters"
        :spinnerMessage="'Searching for Printers'"
        class="spinner"
      />
    </div>
    <div
      @click="closeModalFromOutside"
      v-if="reviewOrder"
      class="reviewOrder modal"
      tabindex="-1"
      role="dialog"
      style="display: block"
    >
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <ul>
            <OrderReview
              :id="reviewOrder.id"
              :channel="channel"
              :order="reviewOrder"
            />
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Socket } from "phoenix";
import Order from "@/components/Order";
import OldOrder from "@/components/OldOrder";
import OrderReview from "@/components/OrderReview";
//import Vue from "vue";
import { api } from "./api";
import dayjs from "dayjs";
import MessageModal from "./Messages/MessageModal.vue";
import LoadingSpinner from "./LoadingSpinner.vue";

//const kdsBackendUrl = "wss://beta.api.beammeup.nz/socket";
//const kdsBackendUrl = "ws://localhost:4000/socket";
const kdsBackendUrl = "wss://" + process.env.VUE_APP_API_HOST + "/socket";

export default {
  name: "Dashboard",
  components: { OldOrder, Order, OrderReview, MessageModal, LoadingSpinner },
  data: function () {
    return {
      channel: null,
      socket: null,
      showSettingsModal: false,
      filters: [{ key: "category", operator: "in", value: "All" }],
      training: false,
      adjustWaitTime: false,
      message: null,
      currentOrders: [],
      oldOrders: [],
      loadingPrinters: false,
      username: null,
      password: null,
      token: null,
      loginError: null,
      code: false,
      digits: ["", "", "", "", "", ""],
      results: null,
      msg: null,
      company: null,
      sites: [],
      site: null,
      stations: [],
      filteredStations: [],
      station: null,
      stationsAvailable: false,
      msgRecieved: null,
      reviewOrder: null,
      messageModal: false,
      msgSite: null,
      msgStation: null,
      time_now: dayjs(),
      msgStyle: { color: "#2c3e50", fontSize: "3.5em" },
      addPrinter: false,
      printer: {},
      printerFound: false,
      printerNotFound: false,
      labelSize: "",
      normal: null,
      later: null,
      late: null,
      printers: [],
      printerselected: false,
      videos: {},
      printerDone: true,
      loading: false,
    };
  },

  created() {
    /* check if JSON Web Token exists */
    setInterval(() => {
      this.time_now = dayjs();
    }, 1000);
  },
  beforeDestroy() {
    this.socket.disconnect();
  },
  beforeMount() {
    window["Beamy"] = {
      components: this,
      printerSearchResult: (printer) => this.listPrinters(printer),
      printerDone: () => this.printerFinished(),
    };
  },

  async mounted() {
    this.token = await window.localStorage.getItem("token");
    this.company = window.localStorage.getItem("company");
    this.site = window.localStorage.getItem("site");
    this.station = window.localStorage.getItem("station");
    this.sites = await window.localStorage.getItem("sites");
    this.sites = JSON.parse(this.sites);
    this.stations = await window.localStorage.getItem("stations");
    this.stations = JSON.parse(this.stations);
    this.filterStations();
    let result = JSON.parse(window.localStorage.getItem("printer"));
    if (result != null) {
      this.printer = result;
      this.printerSelected = true;
    }
  },
  watch: {
    station: {
      handler: function () {
        this.setUpChannel();
      },
    },
  },
  computed: {
    codeSubmitted() {
      return this.digits.join("");
    },

    getLabels() {
      return JSON.parse(window.Android.getLabels());
    },
    now() {
      return this.time_now.format("HH:mm:ss");
    },
    siteCategories() {
      if (this.station && this.stations) {
        var station = this.stations.find((s) => {
          return s.name === this.station;
        });
        return station.categories;
      }
      return [];
    },
    filteredOrders() {
      this.setStationFilter();
      if (
        true &&
        this.filters &&
        this.filters.length &&
        this.filters[0].value === "All"
      ) {
        return this.currentOrders;
      } else if (this.filters && this.filters.length) {
        var filter = this.filters[0];
        return this.currentOrders.filter((order) => {
          return order.data.items.find((item) => {
            if (filter.operator === "in") {
              return filter.value.includes(item[filter.key]);
            }
          });
        });
      } else {
        return this.currentOrders;
      }
    },
  },
  methods: {
    printerFinished() {
      console.log("printed");
      this.printerDone = true;
    },
    saveNewWaitTimes() {
      if (this.normal == null && this.later == null && this.late == null) {
        document.querySelector(".err-msg").style.display = "block";
        document.querySelectorAll(".waitTime").forEach((el) => {
          el.style.border = "1px solid red";
        });
        return;
      } else {
        let waitTimes = {
          normal: this.normal * 60,
          later: this.later * 60,
          late: this.late * 60,
        };
        window.localStorage.setItem("waitTimes", JSON.stringify(waitTimes));
        this.closePrinterModal();
      }
    },

    printEvent() {
      this.showSettingsModal = false;
      this.addPrinter = true;
      console.log("received event from child");
      console.log("show setting modal from parent:", this.showSettingsModal);
    },

    openSettingsModal() {
      this.showSettingsModal = !this.showSettingsModal;
    },

    handleInput(idx, event) {
      let current = event.target;
      if (this.digits[idx].length == 1 && idx < this.digits.length - 1) {
        current.nextElementSibling.focus();
      }
    },

    handleKeydown(idx, event) {
      let current = event.target;
      if (event.key == "Backspace" && this.digits[idx] == "" && idx > 0) {
        current.previousElementSibling.focus();
      }
    },

    async onPaste() {
      let paste = await navigator.clipboard.readText();
      let pasteArray = paste.split("");
      if (pasteArray.length !== 6) {
        return;
      }
      pasteArray.forEach((digit, idx) => {
        this.digits[idx] = digit;
      });
    },

    addLabelSize() {
      let label = document.getElementById("labels");
      this.printer["label"] = label.value;
      window.localStorage.setItem("printer", JSON.stringify(this.printer));
      this.printers = [];
      this.closePrinterModal();
    },

    listPrinters(printers) {
      this.loadingPrinters = false;
      if (printers === []) {
        this.printerNotFound = true;
      } else {
        this.printerFound = true;
        this.printers = printers;
      }
    },

    newPrinter(printer) {
      this.printer["ip"] = printer[0];
      this.printer["model"] = printer[1].replace("-", "_");
      this.printer["label"] = "";
      this.printerSelected = true;
      console.log("new printer added", printer);
    },
    async trainingToggle() {
      this.training = !this.training;
      if (this.training) {
        this.videos = await api.get("/api/videos/" + this.company);
      }
    },
    async selectSite(site) {
      this.site = site;
      await window.localStorage.setItem("site", this.site);
      this.filterStations();
    },
    selectStation(station) {
      this.station = station;
      window.localStorage.setItem("station", this.station);
    },
    sendItemStarted(id, pk) {
      //console.log(id, pk);
      let update = {
        type: "started",
        time: new Date().toISOString(),
        station: this.station,
      };
      this.channel.push("item:started", { id: id, pk: pk, event: update });
    },
    setStationFilter() {
      if (this.stations) {
        var stationObj = this.stations.find((s) => {
          return s.name === this.station;
        });
        if (stationObj) {
          this.filters[0].operator = "in";
          this.filters[0].value = stationObj.categories;
        }
      }
    },
    async getKdsInfo() {
      // this.company = "bagels"
      return api
        .get('/api/company/get_kds/"' + this.company + '"')
        .then(async (data) => {
          /* inject the sites and station data from the returned results */
          /* being a little pedantic with the conditionals here from experience */
          if (
            data.rows &&
            data.rows.length &&
            data.rows[0].value &&
            data.rows[0].value.sites &&
            data.rows[0].value.sites.length
          ) {
            this.sites = data.rows[0].value.sites;
            await window.localStorage.setItem(
              "sites",
              JSON.stringify(this.sites)
            );
            if (
              data.rows &&
              data.rows.length &&
              data.rows[0].value &&
              data.rows[0].value.stations &&
              data.rows[0].value.stations.length
            ) {
              this.stations = data.rows[0].value.stations;
              await window.localStorage.setItem(
                "stations",
                JSON.stringify(this.stations)
              );
              this.stationsAvailable = true;
            }
          }
        })
        .catch((error) => {
          console.log("Error retrieving KDS information", error);
        });
    },
    closeModalFromOutside() {
      this.reviewOrder = null;
    },
    closePrinterModal() {
      this.addPrinter = null;
    },
    closeMessageModal() {
      this.messageModal = false;
    },
    logout() {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("company");
      window.localStorage.removeItem("site");
      window.localStorage.removeItem("sites");
      window.localStorage.removeItem("station");
      window.localStorage.removeItem("stations");
      this.socket.disconnect();
      this.token = null;
      this.sites = null;
      this.site = null;
      this.stations = null;
      this.station = null;
      this.loading = false;
    },
    async login(event) {
      this.loading = true;
      event.preventDefault();
      let auth = {
        user: this.username || "",
        password: this.password || "",
      };
      this.loginError = null;
      api
        .post("/api/user/login", auth)
        .then((result) => {
          if (result === "user not found" || result === "unauthorized") {
            throw result;
          }
          this.company = result.company;
          this.token = result.jwt_token;
          window.localStorage.setItem("token", this.token);
          window.localStorage.setItem("company", this.company);
        })
        .then(async () => {
          await this.getKdsInfo();
        })
        .catch((error) => {
          this.loginError = error;
          this.loading = false;
        });
    },

    async codeLogin() {
      let result = await api.device_login(parseInt(this.codeSubmitted));

      if (result == "error") {
        this.loginError = "Login Failed";
        return;
      }

      this.company = result.data.company;
      this.token = result.jwt_token;
      window.localStorage.setItem("token", result.jwt_token);
      window.localStorage.setItem("company", result.data.company);
      window.localStorage.setItem("site", result.data.site);
      window.localStorage.setItem("station", result.data.station);

      await this.getKdsInfo();
      this.setStationFilter();

      this.station = result.data.station;
      this.site = result.data.site;
    },

    filterStations() {
      if (this.stations) {
        this.filteredStations = this.stations.filter((station) => {
          return station.site === this.site;
        });
      } else {
        this.filteredStations = [];
      }
    },
    reviewOldOrder: function (order) {
      this.reviewOrder = order;
    },
    restoreOrder: function (order) {
      this.oldOrders = this.oldOrders.filter((oldOrder) => {
        return oldOrder != order;
      });
    },
    /***filter(filterStr) {
      Vue.set(this, "filters", [
        { key: "category", operation: "==", value: filterStr },
      ]);
    },***/
    setUpChannel() {
      /* establish the web socket connection */
      this.socket = new Socket(kdsBackendUrl, {
        params: { guardian_token: this.token, station: this.station },
      });
      console.log("Attempt to connect to socket with authentication");
      if (this.token && this.site) {
        console.log("Ready to connect socket....");
        this.socket.connect();

        /* define the channel */
        if (this.channel) {
          this.channel.leave();
        }

        this.channel = this.socket.channel(
          "kds:" + this.company + ":" + this.site,
          {}
        );

        /* handle incoming currentOrders on the channel */
        this.channel.on("order:item:completed", (payload) => {
          if (payload) {
            /* payload = { id: id, pk: pk } */

            var order = this.currentOrders.find((order) => {
              return order.id === payload.id;
            });
            if (order) {
              var item = order.data.items.find((item) => {
                return item.pk === payload.pk;
              });
              if (item) {
                item.complete = true;
              }

              /* we only want to complete items for this station */
              let stationItems = order.data.items.filter((item) => {
                return this.siteCategories.includes(item.category);
              });

              if (
                stationItems.every((item) => {
                  return item.complete === true;
                })
              ) {
                order.complete = true;
                this.setComplete(order.id);
              }
            }
          }
        });

        this.channel.on("order:new", (payload) => {
          payload.rows.forEach((row) => {
            let allData = row.value;
            allData["cell"] = row.doc.cell;
            allData["email"] = row.doc.email;
            this.currentOrders.push({
              id: row.id,
              dbkey: row.key,
              data: row.value,
            });
            const playerChirp = document.getElementById("newOrderChirp");
            const playerBell = document.getElementById("newOrderBell");
            let cats = Object.values(this.siteCategories).includes('Coffee', 'Over Ice');
            if (cats) {
              playerBell.play();
              console.log("bell");
            } else {
              playerChirp.play();
              console.log("chirp");
            }
          });
        });

        this.channel.on("order:updated", (payload) => {
          console.log("Payload ", payload.rows[0]);
          var id = payload.rows[0].id;

          this.currentOrders.forEach(function (item, i, a) {
            if (item.id == id) {
              //console.log(item.id, id, i);
              a.splice([i], 1, {
                id: payload.rows[0].id,
                dbkey: payload.rows[0].key,
                data: payload.rows[0].value,
              });
              return;
            }
          });

          let _this = this;
          this.oldOrders.forEach(function (item, i, a) {
            if (item.id == id) {
              //console.log(item.id, id, i);
              a.splice([i], 1);
              _this.currentOrders.push({
                id: payload.rows[0].id,
                dbkey: payload.rows[0].key,
                data: payload.rows[0].value,
              });
            }
          });
        });

        this.channel.on("order:item:recalled", (payload) => {
          var id = payload.id;
          // var pk = payload.pk;
          var order = null;

          order = this.currentOrders.find((o) => {
            return id === o.id;
          });

          /* order doesn't exists - splice it into the currentOrders array and remove it from the oldOrders */
          if (!order) {
            order = this.oldOrders.find((o) => {
              return id === o.id;
            });
            if (order) {
              this.oldOrders = this.oldOrders.filter((o) => {
                return o.id !== id;
              });
              this.currentOrders.splice(0, 0, order);
            }
          }

          var item = order.data.items.find((item) => {
            return item.pk === payload.pk;
          });

          if (item) {
            item.complete = false;
          }
        });
        this.channel.on("message:sent", (payload) => {
          console.log(payload);
          this.msg = payload;
          this.msgRecieved = true;
          this.msgStyle.color = "red";
          this.msgStyle.fontSize = "6em";
        });
        this.channel.on("order:item:started", (i) => {
          //console.log("ws recieve: ", id);
          this.currentOrders.forEach((order) => {
            if (order.id == i.id) {
              //console.log(i.id, i.pk, order);
              order.data.items.forEach((item) => {
                if (item.pk == i.pk) {
                  item.started = true;
                  if ("events" in item && item.events.length) {
                    item.events.push(i.event);
                  } else {
                    item.events = [];
                    item.events.push(i.event);
                  }
                  //console.log(item);
                }
              });
            }
          });
          //this.started = id;
        });
        /* join the channel */
        this.channel
          .join()
          .receive("ok", () => {
            /* get new orders for the first time */
            this.getNewOrder();
            console.log("Connected");
          })
          .receive("error", (error) => {
            this.station = null;
            this.socket.disconnect();
            this.stationError = error;
            console.log("Error: " + JSON.stringify(error));
          });
      }
    },
    searchForPrinter() {
      if (window.Android) {
        window.Android.searchForPrinter();
      }
      console.log("call android");
      this.loadingPrinters = true;
    },
    getNewOrder() {
      api
        .get("/api/get_all/" + this.company + "/" + this.site)
        .then(async (data) => {
          const old = [];
          const n = [];
          data.rows.forEach((row) => {
            //console.log(row);
            let complete = row.value.items.filter(
              (item) =>
                !item.complete && this.siteCategories.includes(item.category)
            );
            //console.log(complete);
            if (complete.length == 0) {
              old.push({ id: row.id, dbkey: row.key, data: row.value });
            } else {
              n.push({ id: row.id, dbkey: row.key, data: row.value });
            }
            //return { id: row.id, dbkey: row.key, data: row.value };
          });
          this.currentOrders = n.reverse();
          this.oldOrders = old;
        });
    },
    messages() {
      this.messageModal = true;
    },
    setComplete(id) {
      const order = this.currentOrders.find((order) => {
        return order.id === id;
      });
      if (order) {
        this.oldOrders.splice(0, 0, order);
      }

      this.currentOrders = this.currentOrders.filter((order) => {
        return order.id !== id;
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
ul#filters {
  display: none;
  position: absolute;
  min-height: 100%;
  background-color: #f5f5dc;
  list-style: none;
  margin: 0px;
  left: 0px;
  top: 0px;
  padding: 20px;
  width: 200px;

  img {
    margin-left: -20px;
    margin-top: -20px;
  }
  li button {
    height: 100%;
    margin-bottom: 10px;
    display: block;
    width: 100%;
    font-size: 2em;
    padding: 1em 0em 1em 0em;
  }

  li:nth-child(1) button {
    border: none;
    background-color: orange;
  }
  li:nth-child(2) button {
    border: none;
    background-color: greenyellow;
  }
  li:nth-child(3) button {
    border: none;
    background-color: lightpink;
  }
}

ul#old {
  position: absolute;
  min-height: 100%;
  background-color: #ddd;
  list-style: none;
  margin: 0px;
  top: 0px;
  left: 0px;
  height: 100%;
  overflow-y: scroll;
  padding: 0px;
  width: 200px;
  z-index: 100;
}

ul#active {
  list-style: none;
  gap: 10px;
  padding-left: 210px;
  grid-template-rows: 100vh;
  grid-auto-flow: column;
  grid-auto-columns: minmax(auto, max-content);
  //height: 100vh;
  overflow-x: scroll;
  margin-right: 10px;
  //overflow-y: scroll;
  li {
    height: fit-content;
    border-radius: 0.5rem;
    border: 1px solid #fff;
  }
  // li .item {
  // }
  li:last-child {
    margin-right: 20px;
  }
}

#siteSelector {
  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    li {
      padding: 10px;
      border-bottom: 2px solid #ccc;
      display: inline-block;
      text-align: center;
      width: 200px;
      border: 1px solid #ccc;
      margin: 10px;
      padding: 10px;
      cursor: pointer;
    }
    li:hover {
      background-color: #ccc;
    }
  }
  text-transform: capitalize;
}

#stationSelector {
  .modal-dialog {
    min-width: 800px;
  }
  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    li {
      padding: 10px;
      border-bottom: 2px solid #ccc;
      display: inline-block;
      text-align: center;
      width: 210px;
      border: 1px solid #ccc;
      margin: 10px;
      padding: 10px;
      cursor: pointer;
    }
    li:hover {
      background-color: #ccc;
    }
  }
  text-transform: capitalize;
}

#messageModal {
  background-color: transparent;
}

#messageModal ul {
  height: auto;
}

#messageModal ul li {
  background-color: #97b0c2;
  padding: 4px;
  margin: 8px;
  border-radius: 0.2rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  min-width: 120px;
}

#messageModal form {
  padding: 10px;
}

#msgLink {
  padding: 10px;
  position: absolute;
  right: 0px;
  bottom: 0;
}

.reviewOrder ul {
  list-style: none;
  white-space: nowrap;
  margin: 0px;
  height: 600px;
  //width: 400px;
  padding: 0px;
  overflow-y: scroll;
  li:first-child {
    padding-bottom: 20px;
  }
}

.digits {
  display: flex;
  flex-direction: row;
  justify-content: center;
  .digit-input {
    text-align: center;
    width: 3rem;
    height: 4rem;
    margin: 0.5rem;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  }
}

.radius {
  border-radius: 5px !important;
}

.action-head {
  color: #3bafda;
  text-align: left;
  margin: 0 0 0 1rem;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.light-bg {
  background-color: #ffffff90 !important;
  overflow: scroll !important;
}

.button {
  width: 100%;
  padding: 10px;
  margin: 10px;
  border: none;
  border-radius: 0.5rem;
  color: #2e2f35;
  font-weight: bold;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4);
  p {
    margin: 2px;
    font-size: medium;
  }
}

.modal-content {
  width: 100%;

  .setting {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    margin: 1rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
    h3 {
      text-align: center;
    }
    button {
      width: 100%;
      padding: 10px;
      margin: 10px;
      border: none;
      border-radius: 0.5rem;
      color: #2e2f35;
      font-weight: bold;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4);
    }
    .btn-2 {
      background-color: #2e2f35 !important;
      color: #fff !important;
    }
    .setting-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .time-input {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        // padding: 0.5rem;
        border: none;
        label {
          width: 50%;
          text-align: center;
          text-wrap: nowrap;
          margin: 0.5rem;
        }
        input {
          width: 50%;
          margin: 0.5rem;
          padding: 0.25rem;
          border: none;
          border-radius: 0.5rem;
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
        }
        .err-msg {
          display: none;
          width: 100%;
          padding: 10px;
          margin: 10px;
          border: none;
          border-radius: 0.5rem;
          color: #2e2f35;
          font-weight: bold;
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4);
        }
      }
    }
  }
}

.modal {
  background-color: #2e2f35;
  .login-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .container {
    background-color: #444548;
    width: 45%;
    padding: 2rem;
    // margin: 0 auto;
    border-radius: 0.5rem;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
    border: none;
    max-height: 100vh;
    overflow-y: auto;
    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #d8d5d5;
      border: none;
      img {
        width: 25rem;
        margin: 10px;
      }
    }
    .form-container {
      margin: 0;
      .form {
        margin: 0;
        input {
          width: 100%;
          border: none;
          border-radius: 0.5rem;
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
        }
      }
    }
    .footer {
      border: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0;
      .btn {
        height: 3rem;
        width: 100%;
        background-color: #3bafda;
        color: #2e2f35;
        margin: 0.5rem 0;
        border-radius: 0.5rem;
        font-weight: bold;
        border: none;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4);
      }
    }
  }

  .error {
    text-align: left;
    font-size: 1.2em;
    margin-left: 20px;
    text-transform: capitalize;
    color: red;
  }
}

#currentTime {
  margin: 0px;
  padding: 0px;
  font-weight: bold;
  float: left;
  font-size: 1.3em;
  margin-left: 20px;
}
#currentOrdersCount {
  font-size: 1.3em;
  float: right;
  margin-right: 20px;
}

@media only screen and (orientation: portrait) {
  .modal {
    .container {
      width: 100%;
    }
  }
}

@media (min-width: 800px) and (max-width: 994px) {
  .modal {
    .container {
      width: 90%;
    }
  }
}
</style>
